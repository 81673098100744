import styled, { css } from 'styled-components'

const Text = styled.div`
  /* hyphens: auto; */

  ${props =>
    props.align === 'center' &&
    css`
      text-align: center;
    `}

  ${props =>
    props.size === 'intro' &&
    css`
      font-size: 1.5rem; /* 24px */
      line-height: 1.55;
      width: 890px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media (max-width: 1024px) {
        font-size: 1.25rem; /* 20px */
      }

      @media (max-width: 375px) {
        font-size: 1.125rem;
      }
    `}

  ${props =>
    props.columns &&
    css`
      columns: auto 2;
      column-gap: 120px;

      p {
        break-inside: avoid-column;
      }

      @media (max-width: 1024px) {
        column-gap: 60px;
      }

      @media (max-width: 768px) {
        columns: inherit;
      }
    `}
`

export default Text
