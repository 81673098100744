import React from 'react'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Content from '../components/Content'
import Text from '../components/Text'
import Intro from '../components/Intro'
import Title from '../components/Title'
import Spacer from '../components/Spacer'
import { useIntl } from '../../plugins/gatsby-plugin-intl'

const Pdfs = styled.div`
  a {
    color: ${props => props.theme.colors.primary};

    &:hover {
      font-weight: 500;
    }
  }
`

const PrivacyPolicyPage = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'nav.privacy' })} />
      <Intro image={data.introImage} />
      <Content>
        <Title align="center">
          {intl.formatMessage({ id: 'nav.privacy' })}
        </Title>
        <Fade>
          <Text align="center">
            <Pdfs>
              {intl.locale === 'de' ? (
                <>
                  <a href="/OJR_DSE_Kunden_DE.pdf" target="_blank">
                    Datenschutzerklärung für Kunden
                  </a>
                  <br />
                  <a href="/OJR_DSE_Webseitenbesucher_DE.pdf" target="_blank">
                    Datenschutzerklärung für Webseitenbesucher
                  </a>
                  <br />
                  <a href="/OJR_DSE_Bewerber_DE.pdf" target="_blank">
                    Datenschutzerklärung für Stellenbewerber
                  </a>
                </>
              ) : (
                <>
                  <a href="/OJR_DSE_Kunden_EN.pdf" target="_blank">
                    Privacy Notice for Clients
                  </a>
                  <br />
                  <a href="/OJR_DSE_Webseitenbesucher_EN.pdf" target="_blank">
                    Data Protection & Privacy Policy for Visitors of our website
                  </a>
                  <br />
                  <a href="/OJR_DSE_Bewerber_EN.pdf" target="_blank">
                    Privacy Notice for Job Applicants
                  </a>
                </>
              )}
            </Pdfs>
          </Text>
        </Fade>
      </Content>
      <Spacer />
    </Layout>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query {
    introImage: file(relativePath: { eq: "anwaelte_head.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
