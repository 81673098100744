import styled from 'styled-components'

const Spacer = styled.div`
  height: 3rem;
  height: 170px;
  display: block;

  @media (max-width: 768px) {
    height: 100px;
  }

  @media (max-width: 375px) {
    height: 60px;
  }
`

export default Spacer
